<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "OrderApplications",
  emits: ['applicationDeleted'],
  props: {
    order_number: {
      type: Number,
      required: true
    },
    application: {
      type: [null, String],
      required: true,
      default: () => null
    }
  },
  methods: {
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    deleteApplicationConfirmation() {
      Swal.fire({
        position: "center",
        icon: "info",
        html: `
            <h3>Are you sure that <br> you want to delete this application </h3>
        `,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        confirmButtonColor: '#DC3741',
        focusConfirm: false,
        inputLabel: `Please type YES to confirm`,
        input: 'text',
        inputPlaceholder: 'YES',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.toLowerCase() === 'yes') {
              resolve(this.deleteApplication())
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    async deleteApplication() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        await axios.delete(`/wagon_order/for_application/${this.order_number}/delete/`)
        this.$emit('applicationDeleted')
        await Toast.fire({
          icon: "success",
          title: "Application deleted successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  }
}
</script>

<template>

  <div class="card"><!----><!---->
    <div class="card-header">
      <h6 class="card-title fw-semibold mb-0">Applications</h6>
    </div>

    <div v-if="application" class="card-body">
      <b-row gutter-y="3" gutter-x="2">
        <b-col :xl="3">
          <div class="avatar-sm w-100">
            <div class="avatar-title bg-light rounded"><i class="bx bx-file fs-20 text-primary"></i></div>
          </div>
        </b-col>
        <b-col :xl="9">
          <div class="d-flex flex-column justify-content-between h-100">
            <h6 class="mb-0 fs-12">
              <a class="truncate-2-lines">
                {{ application.file.split('/').pop() }}
              </a>
            </h6>
            <small class="text-muted">{{
                ((application.size || 0) / (1024 * 1024)).toLocaleString(undefined, {
                  minimumFractionDigits: 2, maximumFractionDigits: 2
                })
              }} MB
            </small>
          </div>
        </b-col>
        <b-col :sm="6">
          <b-button @click="downloadFile(application.file)" size="sm" variant="soft-primary" class="w-100">Download
          </b-button>
        </b-col>
        <b-col :sm="6">
          <b-button @click="deleteApplicationConfirmation()" size="sm" variant="soft-danger"
                    class="w-100">
            Delete
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div v-else class="card-body"><!----><!---->
      <h4 class="card-title">Create a new Application</h4>
      <p class="card-text text-muted">
        Click below to instantly create your application.
      </p>
      <router-link :to="{ name: 'create_wagon_application', params: {id: order_number}}">
        <button class="btn btn-success">Create Now</button>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>