<script>
import Swal from "sweetalert2";
import axios from "axios";
import store from "@/state/store";

export default {
  emits: ['invoiceDeleted'],
  props: {
    invoices_list: {
      type: Array,
      required: true
    },
    order_number: {
      type: Number,
      required: true
    }
  },
  computed: {
    user() {
      return store.state.user;
    },
    invoice_in_query() {
      return this.$route.query.invoice
    }
  },
  methods: {
    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    deleteInvoiceConfirmation(invoice) {
      Swal.fire({
        position: "center",
        icon: "info",
        html: `
            <h3>Are you sure that <br> you want to delete this invoice </h3>
        `,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        confirmButtonColor: '#DC3741',
        focusConfirm: false,
        inputLabel: `Please type YES to confirm`,
        input: 'text',
        inputPlaceholder: 'YES',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.toLowerCase() === 'yes') {
              resolve(this.deleteInvoice(invoice))
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    async deleteInvoice(invoice) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        await axios.delete(`/invoice/delete/${invoice.id}/`)
        this.$emit('invoiceDeleted', invoice)
        await Toast.fire({
          icon: "success",
          title: "Invoice deleted successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h6 class="card-title fw-semibold mb-0">Invoices</h6>
    </div>
    <div class="card-body d-flex flex-column gap-3">
      <b-row v-for="invoice in invoices_list" :key="`invoice_${invoice.id}`" gutter-y="3" gutter-x="2">
        <b-col :xl="3">
          <div class="avatar-sm w-100">
            <div class="avatar-title bg-light rounded"><i class="bx bx-file fs-20 text-primary"></i></div>
          </div>
        </b-col>
        <b-col :cl="9">
          <h6 class="mb-0 fs-12">
            <a class="truncate-2-lines">
              {{ invoice.file.split('/').pop() }}{{ invoice.file.split('/').pop() }}
            </a>
          </h6>
          <small class="text-muted">{{
              ((invoice.file_size || 0) / (1024 * 1024)).toLocaleString(undefined, {
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })
            }} MB
          </small>
        </b-col>
        <b-col :sm="6">
          <b-button @click="downloadFile(invoice.file)" size="sm" variant="soft-primary" class="w-100">Download
          </b-button>
        </b-col>
        <b-col :sm="6">
          <b-button
              v-if="user.role.toLowerCase() === 'admin'"
              @click="deleteInvoiceConfirmation(invoice)" size="sm" variant="soft-danger" class="w-100">Delete
          </b-button>
        </b-col>
      </b-row>
      <div v-if="invoices_list.length === 0">
        <div class="avatar-sm mb-3">
          <div class="avatar-title bg-soft-success text-success fs-17 rounded">
            <i class="bx bx-file"></i>
          </div>
        </div>
        <h4 class="card-title">Create a new Invoice</h4>
        <p class="card-text text-muted">
          Click below to instantly generate your invoice.
          It's quick, easy, and keeps your records up-to-date.
        </p>

        <router-link :to="{ name: 'wagon_invoice_create', params: {order_number: order_number}}">
          <button class="btn btn-success">Create Now</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>